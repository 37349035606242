.editable-cell {
  border: none; /* Sin borde por defecto */
}

.editable-cell input {
  border: 1px solid transparent; /* Sin borde por defecto */
  transition: border-color 0.3s; /* Suaviza la transición del borde */
  width: 100%;
  max-width: 800px; /* Ajusta según sea necesario */
  padding: 0.2rem;
  color: #000; /* Color del texto */
  background-color: #f9f9f9; /* Fondo más claro para el input */
}

.editable-cell input::placeholder {
  color: #d6d4d4; /* Color más claro para el placeholder */
  opacity: 1; /* Asegura que el color se aplique correctamente */
}

.editable-cell input:focus {
  border-color: #007bff; /* Cambia el color del borde al hacer clic */
  outline: none; /* Sin contorno por defecto */
}

.scrollable-table {
  max-height: 320px;
  overflow-y: auto;
  margin-top: 1rem;
}
.data-table-container {
  max-height: 340px;
  overflow-y: auto;
}

.input-cell {
  width: auto; /* Ajusta según sea necesario */
}

.input-cell:nth-child(1) {
  width: 25px;
}

.input-cell:nth-child(2) {
  width: 180px;
}

.input-cell:nth-child(3) {
  width: 180px;
}

.input-cell:nth-child(4) {
  width: 180px;
}

.input-cell:nth-child(5) {
  width: 40px;
}

.input-cell:nth-child(6) {
  width: 60px;
}

.error-message {
  color: red;
  font-size: 12px;
}

/* Estilo para las filas con errores */
.error-field {
  background-color: #f30d241c !important; /* Fondo rojo claro */
}

/* Estilos para la tabla */
table {
  width: 100%;
}

/* Fija los encabezados de la tabla */
thead {
  position: sticky;
  top: 0;
  z-index: 1; /* Asegura que el encabezado quede por encima del contenido */
  height: 20px;
}

/* Clase personalizada para el modal a pantalla completa */
.fullscreen-modal .modal-dialog {
  max-width: 100%; /* Sin límite de ancho */
  height: 100vh; /* 100% de la altura de la ventana */
  margin: 0; /* Elimina el margen */
  padding: 0; /* Elimina el padding */
}

.fullscreen-modal .modal-content {
  height: 100%; /* Asegura que el contenido ocupe toda la altura */
  border-radius: 0; /* Opcional: Elimina los bordes redondeados */
}

.fullscreen-modal .modal-header,
.fullscreen-modal .modal-footer {
  background-color: #f1f1f1; /* Personaliza el color de fondo del header y footer si es necesario */
}

.fullscreen-modal .modal-body {
  max-height: calc(
    100vh - 120px
  ); /* Restamos la altura del header y footer para que el cuerpo sea desplazable */
  overflow-y: auto; /* Permite el desplazamiento si el contenido es largo */
  padding: 10px 20px; /* Ajusta el padding del cuerpo */
}

/* Opcional: Personalizar el botón de cerrar */
.fullscreen-modal .modal-header button {
  font-size: 1.2rem;
}
