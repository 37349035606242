.nav-item {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    margin: 0 5px;
    border-radius: 4px;
    transition: background-color 0.3s;
    cursor: pointer;
  }
  
  .nav-item:hover {
    background-color: #555; /* Cambia el color de fondo al pasar el mouse */
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  
  }
  
  .pulse-item {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    margin: 0 5px;
    border-radius: 4px;
    background-color: #aa2228; /* Color brillante */
    color: #000;
    font-weight: bold;
    animation: pulse 1.5s infinite; /* Pulsar */
    cursor: pointer;
  }
  .dropdown-toggle {
    display: none; /* Oculta el caret */
  }
  .dropdown-toggle:hover {
    display: none; /* Oculta el caret */
  }
  