.skeleton-card {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(195px, 1fr));
  gap: 50px;
  margin-top: 100px;
}

.skeleton-card-item {
  background-color: #e0e0e0;
  border-radius: 4px;
  height: 160px;
  animation: shimmer 1s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}
