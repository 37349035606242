/* Estilo general */
.container {
    padding: 20px;
  }
  
  /* Estilos para pantallas pequeñas */
  @media (max-width: 576px) {
    .embed-responsive {
      width: 100%;
      height: 650px; /* Altura fija para el video */
      overflow: hidden; /* Asegúrate de que no se desborde */
    }
  
    video {
      width: 100%; /* Ancho completo */
      height: 100%; /* Altura completa */
      object-fit: cover; /* Asegura que el video cubra el contenedor */
    }
  
    .btn-container {
      position: fixed; /* Fijar el botón en la parte inferior */
      bottom: 20px; /* Espacio desde el fondo */
      left: 50%;
      transform: translateX(-50%); /* Centrar horizontalmente */
      width: 100%; /* Ancho completo */
      padding: 0 15px; /* Espaciado lateral */
    }
  }
  /* Estilo del footer */
  .footer-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 64px;
    background-color: #045cad; /* Color de fondo opcional */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Sombra opcional */
    z-index: 1000; /* Asegúrate de que esté encima del contenido */
  }
  
  .footer-button {
    font-size: 24px; /* Tamaño del ícono */
    padding: 10px 20px; /* Espaciado del botón */
    margin-top: 2px;
  }
  
  
  /* Estilos para dispositivos más grandes */
  @media (min-width: 577px) {
    .embed-responsive {
      height: auto; /* Deja que se ajuste automáticamente */
    }
  }
  