.date-picker {
  height: 100%;
}

.form-control-lg {
  height: calc(3.25rem + 2px); /* Ajusta según sea necesario */
  width: 80%;
}
.custom-placeholder-add::placeholder {
  font-size: 1.1rem; /* Ajusta el tamaño según sea necesario */
}
.custom-placeholder-add {
  width: 100%;
  min-height: 50px;
  max-height: 200px;
  overflow-y: auto;
}
