/* SkeletonGrid.css */

.skeleton-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Mantiene las 4 columnas */
  grid-template-rows: repeat(3, 80px); /* Define 4 filas */
  gap: 5px; /* Espacio entre los elementos */
  margin-top: 50px;
}

.skeleton-row {
  display: contents; /* Permite que los items se alineen en la cuadrícula */
}

.skeleton-item {
  background-color: #e0e0e0; /* Color de fondo para el efecto de carga */
  border-radius: 4px; /* Bordes redondeados */
  height: 40%; /* Ocupa la altura de la fila */
  animation: shimmer 1s infinite; /* Animación de brillo */
}

@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}
