.boton-close-modal {
  font-size: 20px;
  padding: 6px 15px;
}

.span-quantity-cards {
  font-size: 32px;
  font-weight: 700;
}

.container-message-qr {
  font-size: 28px;
  margin-right: 4px;
}

.spanMesaContainer {
  display: inline-block;
  margin-top: 10px;
  background-color: rgb(204, 211, 211);
  padding: 2px 12px;
  border-radius: 5px;
}
