.card-eventos {
  background-color: #468fee28;
  border: 2px solid #17a2b8;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  height: 180px;
  font-size: 22px;
  transition: transform 0.2s, background-color 0.2s;
}

.card-eventos:hover {
  background-color: #61a5ff50;
  transform: scale(1.05);
  border-color: #138496;
}

.card-eventos-passed {
  background-color: #b9c0c928;
  border: 2px solid #acafaf;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  height: 180px;
  font-size: 22px;
  transition: transform 0.2s, background-color 0.2s;
}

.card-eventos-passed:hover {
  background-color: #67676850;
  transform: scale(1.05);
  border-color: #9d9e9e;
}

.card-eventos .card-eventos-passed .card-title {
  font-size: 20px;
}

.card-title {
  color: #292d31;
}

.card-title-passed {
  color: #a6a8aa;
}

.custom-select {
  width: 160px;
  margin-left: 10px;
}

.card-body-eventos {
  max-height: 105px;
  overflow: hidden;
}

.link-card {
  color: inherit;
  text-decoration: none;
  padding-bottom: 15px;
}

.container-date-picker {
  width: 100%; /* Cambiado a 100% para ser más responsivo */
  max-width: 900px; /* Ajusta según sea necesario */
}

.container-eventos {
  max-height: 400px; /* Ajusta según sea necesario */
  overflow-y: auto; /* Permite el desplazamiento solo si hay más contenido del que cabe */
}
.btn-limpiar {
  padding: 10px 18px; /* Ajusta estos valores según sea necesario */
  display: flex;
  align-items: center;
}
.btn-agregar-eventos {
  margin-left: 8px; /* Asegúrate de que no tenga margen izquierdo */
}
